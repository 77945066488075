<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "supplierParent",
  watch: {
    "$route.query": {
      immediate: true,
      deep: true,
      handler(query) {
        if (!query.prj_oid) {
          this.$router.replace({path: '/supplierProject'})
        } else if (this.$route.name === 'supplierParent'){
          this.$router.replace({path: '/supplier/plan', query: {prj_oid: query.prj_oid}})
        }
      }
    }
  }
}
</script>